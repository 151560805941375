<template>
  <div>
    <!-- 学生报名信息查看 -->
    <div class="container">
      <div class="notice-box bottom">
        <!-- 返回 -->
        <div class="go-back">
          <!-- <div class="go-back-box" @click="goBack">
            <i class="el-icon-d-arrow-left" />
            返回
          </div> -->
        </div>

        <!-- 标题 -->
        <div class="title">
          <i style="font-size:25px" class="iconfont icon-yonghuxinxi" />
          <span class="title-text">参赛信息</span>
        </div>
        <div style="margin-bottom: 30px;" class="body">
          <WorkInfo ref="workinfo" :preform="form" :is-check="isCheck" @form="form=$event" @reswork="res_work=$event" />
        </div>

        <!-- </div>

      <div class="notice-box bottom"> -->
        <div class="title">
          <i style="font-size:25px" class="iconfont icon-tupian" />
          <span class="title-text">作品信息</span>
        </div>
        <div class="body bottom">
          <EnrollInfo
            ref="enrollinfo"
            :preform="form"
            :is-loading.sync="isLoading"
            :is-pic-upload="isPicUpload"
            :is-model-upload="isModelUpload"
            :is-video-upload="isVideoUpload"
            :is-check="isCheck"
            @form="enrollForm=$event"
          />
        </div>
      </div>

      <div v-if="status=='check'" class="btn-place">
        <el-button class="btn" type="primary" @click="gotoEdit">修改</el-button>
      </div>
      <div v-else class="btn-place">
        <el-button class="btn" type="primary" :loading="isLoading" @click="saveEdit">保存修改</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import '@/svg/iconfont/iconfont.css'
import { time } from '@/utils/time'
import WorkInfo from '@/components/WorkInfo'
import EnrollInfo from '@/components/EnrollInfo'
// updateRefistrationInfo
import { retrieveRegistrationInfo, updateRefistrationInfo } from '../../api/work'
export default {
  components: { WorkInfo, EnrollInfo },
  data() {
    return {
      originForm: null,
      // 页面状态，判断页面是在修改界面，还是在查看界面
      // check: 查看界面；edit: 编辑界面
      status: '',
      // contestid
      id: null,
      // 作品id
      workId: null,
      // 是否是在check页面
      isCheck: null,

      // 是否有作品在上传
      isLoading: false,

      res_work: true,
      enrollForm: null,
      // 参赛信息
      form: {
        workName: '', // 作品名称
        authorName: '', // 作者姓名
        birthday: '', // 出生日期
        gender: '', // 姓别
        identityNumber: '', // 身份证号
        studentType: '', // 学生类别
        phone: '', // 手机号码
        email: '', // 作者邮箱
        groupMember: '', // 小组成员（非必须）
        racetrack: '', // 参赛通道
        category: '', // 参赛类别
        university: '', // 所属高校
        department: '', // 所在院系
        grade: '', // 所在年级
        studentNumber: '', // 作者学号
        teacherName: '', // 指导老师
        address: '', // 通讯地址
        specification: '', // 说明
        posterSrc: '', // 平面图url
        modelSrc: '', // 模型url
        videoSrc: '', // 视频url
        id: parseInt(this.$route.params.workId),
        contestName: '',
        acceptance: '',
        contestId: parseInt(this.$route.params.id),
        studentId: null,
        boardId: null
      },
      isPicUpload: true,
      isModelUpload: true,
      isVideoUpload: true,

      isPicReload: false
    }
  },
  created() {
    this.status = this.$route.params.status
    this.id = this.$route.params.id
    this.workId = this.$route.params.workId
    this.isCheck = (this.status === 'check')
  },
  mounted() {
    console.log('mount')
    this.status = this.$route.params.status
    this.id = this.$route.params.id
    this.workId = this.$route.params.workId
    this.isCheck = (this.status === 'check')
    this.init()
  },
  methods: {
    init() {
      retrieveRegistrationInfo(this.workId).then(Response => {
        console.log('response', Response)
        this.form = Response.data
        this.form.category = Response.data.catagory
        this.originForm = this.form
        if (this.form.posterSrc === '') {
          this.isPicUpload = false
        }
        if (this.form.modelSrc === '') {
          this.isModelUpload = false
        }
        if (this.form.videoSrc === '') {
          this.isVideoUpload = false
        }
      })
    },
    // 进行编辑
    gotoEdit() {
      this.status = 'edit'
      this.isCheck = (this.status === 'check')
      this.$router.replace('/mycontest/enrollcheck/edit/' + this.id + '/' + this.workId)
    },
    // 保存编辑信息
    saveEdit() {
      this.$refs.workinfo.validateForm()
      const res_enroll = this.$refs.enrollinfo.validateDisplayInfo()
      if (this.res_work && res_enroll) {
        console.log('OKK')
      } else {
        // console.log('noo ok')
        return
      }
      // 保存编辑信息后
      this.form.specification = this.enrollForm.specification
      this.form.posterSrc = this.enrollForm.posterSrc
      this.form.videoSrc = this.enrollForm.videoSrc
      this.form.modelSrc = this.enrollForm.modelSrc
      this.form.birthday = time.getCommonTime(this.form.birthday)
      console.log('form', this.form)
      if (this.form.workName !== this.originForm.workName || this.originForm.teacherName !== this.form.teacherName ||
      this.form.university !== this.originForm.university || this.originForm.authorName !== this.form.authorName ||
      this.form.groupMember !== this.originForm.groupMember) {
        this.$message('修改关键信息需要重新提交展板图')
        this.originForm = this.form
        this.form.posterSrc = ''
        this.isPicUpload = false
        this.isPicReload = true
        return
      }
      if (this.form.posterSrc !== this.originForm.posterSrc) {
        this.originForm = this.form
        this.isPicReload = true
      }
      this.status = 'check'
      this.isCheck = (this.status === 'check')
      updateRefistrationInfo(this.form).then(Response => {
        if (Response.code === 'OK') {
          const id = Response.data.id
          const contestId = Response.data.contestId
          if (this.isPicReload) {
            const loading = this.$loading({
              lock: true,
              text: '您的报名表和展板图正在生成中...',
              color: 'rgb(255, 255, 255)',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.5)'
            })
            // this.form = Response.data
            setTimeout(() => {
              loading.close()
              this.init()
              this.$router.replace(`/mycontest/enrollcheck/check/${contestId}/${id}`)
            }, 1000 * 10)
            this.isPicReload = false
          } else {
            this.$router.replace(`/mycontest/enrollcheck/check/${contestId}/${id}`)
          }
        }
      })
      // console.log(this.form)
      // console.log('child data1', form1)
      // console.log('child data1', form2)
      // updateRefistrationInfo(this.form).then(Response => {
      //   if (Response.code === 'OK') {
      //     this.$router.push('/mycontest/enrollcheck/check/' + this.id + '/' + this.workId)
      //     console.log(Response.data)
      //   }
      // })
    },
    goBack() {
      this.$router.push('/mycontest/enrollinfo/' + this.id)
    }
  }
}
</script>

<style scoped>
@import '~@/styles/theme.css';

.border {
  border: 1px solid red;
  height: 30px;
}

.container {
  width: 100%;
  min-height: calc(100vh - 60px);
  background-color: var(--background-light-color);
  /* border: 1px solid red; */
  text-align: center;
}

.notice-box {
  position: relative;
  display: inline-block;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  margin-top: 40px;
  min-height: 610px;
  width: 1000px;
  margin-bottom: 10px;
  /* border: 1px solid red; */
}

.go-back {
  width: 90%;
  margin: auto;
  margin-top: 15px;
  text-align: left;
  /* padding-left: 20px; */
  font-size: 14px;
}

.go-back-box {
  display: inline-block;
}

.go-back-box:hover {
  cursor: pointer;
}

.title {
  display: inline-block;
  width: 90%;
  height: 60px;
  margin: auto;
  /* height: 80px; */
  border-bottom: 1px solid var(--background-color);
  text-align: left;
  padding-top: 25px;
}

.title-text {
  display: inline-block;
  font-size: 20px;
  margin-left: 5px;
}

.body {
  display: inline-block;
  width: 90%;
  margin: auto;
  margin-top: 30px;
  /* border: 1px solid red; */
  min-height: 390px;
}

.bottom {
  margin-bottom: 60px;
}

.btn-place {
  padding-bottom: 120px;;
}

.btn {
  width: 120px;
}

.el-button {
  border-radius: 10px;
}

</style>

<style>

.el-loading-spinner .el-loading-text {
  color: #fff !important;
  margin-top: 20px;
  font-size: 20px;
}

.el-loading-spinner i {
  color: #fff !important;
  font-size: 40px;
}
</style>

<template>
  <div class="info-container">
    <!-- 设计说明 -->
    <el-row class="info-row" :gutter="5">
      <el-col :span="4">
        <div class="title">
          设计说明:
          <div class="limit">（必须填写）</div>
          <div class="must-fill">
            <svg-icon name="bitian" color="#fd7b7b" width="20" />
          </div>
        </div>
      </el-col>
      <el-col :span="20">
        <div>
          <el-input
            v-model="form.specification"
            class="frame"
            placeholder="请输入设计说明，字数限制200字"
            :rows="5"
            type="textarea"
            maxlength="200"
            show-word-limit
            :disabled="isCheck"
          />
        </div>
      </el-col>
    </el-row>

    <!-- 平面版图 -->
    <el-row class="info-row" :gutter="5">
      <el-col :span="4">
        <div class="title">
          平面版式:
          <div class="limit">（必须上传）</div>
          <div class="must-fill">
            <svg-icon name="bitian" color="#fd7b7b" width="20" />
          </div>
        </div>
      </el-col>
      <el-col :span="20">
        <div v-if="form.posterSrc===''">
          <el-upload
            ref="boardUpload"
            class="pic-upload-box"
            drag
            :auto-upload="false"
            :on-success="onPicUploadSuccess"
            :on-error="onPicUploadError"
            :headers="getHeads()"
            :action="getUploadPicUrl()"
            :disabled="isCheck"
            :on-change="beforePicUpload"
          >
            <div class="btn-place">
              <svg-icon name="picture" height="33" />
              <div class="edit-btn">
                <el-row>
                  <el-col :span="17">
                    <div class="edit-name">平面版图</div>
                  </el-col>
                  <el-col :span="7">
                    <div class="edit-status unedit">
                      <i class="el-icon-minus" />
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="notice-text color">
              点击或将文件拖拽到这里上传
            </div>
            <div class="file-limit color">
              支持扩展名: .jpg
            </div>
          </el-upload>
          <!-- <el-input class="frame" placeholder="请输入设计说明，字数限制200字" :rows="5" type="textarea" v-model="specification"  /> -->
        </div>
        <div v-else class="pic-box">
          <!-- 图片的宽度是占满的 -->
          <el-image style="height: 100%" :src="previewTiny(form.posterSrc)" :preview-src-list="[previewTiny(form.posterSrc)]" fit="scale-down" lazy />
          <div v-if="!isCheck" class="img-delete-box" @click="deleteSrc('pic')">
            <i style="font-size: 6px" class="el-icon-delete" />
            删除
          </div>
        </div>
      </el-col>
    </el-row>

    <!-- 模型/视频 -->
    <el-row class="info-row" :gutter="5">
      <el-col :span="4">
        <div class="title">
          模型/视频:
          <div class="limit">（可选其一上传）</div>
        </div>
      </el-col>
      <el-col :span="10">
        <div v-if="form.modelSrc===''">
          <el-upload
            ref="modelUpload"
            class="model-upload-box"
            drag
            :headers="getHeads()"
            :action="getUploadModelUrl()"
            :disabled="isCheck"
            :before-upload="beforeModelUpload"
            :on-success="onModelUploadSuccess"
            :on-error="onModelUploadError"
          >
            <div class="btn-place">
              <svg-icon name="model" height="33" />
              <div class="edit-btn">
                <el-row>
                  <el-col :span="17">
                    <div class="edit-name">3D模型</div>
                  </el-col>
                  <el-col :span="7">
                    <div class="edit-status unedit">
                      <i class="el-icon-minus" />
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="notice-text color">
              点击或将文件拖拽到这里上传
            </div>
            <div class="file-limit color">
              支持扩展名: .obj/.dae/.fbx
            </div>
          </el-upload>
        </div>
        <div v-else class="pic-box">
          <div class="play-box">
            <model-obj v-if="getModelSuffix() === 'obj'" :src="previewTiny(form.modelSrc)" />
            <model-collada v-if="getModelSuffix() === 'dae'" :src="previewTiny(form.modelSrc)" />
            <model-fbx v-if="getModelSuffix() === 'fbx'" :src="previewTiny(form.modelSrc)" />

          </div>
          <div v-if="!isCheck" class="delete-box" @click="deleteSrc('model')">
            <i style="font-size: 6px" class="el-icon-delete" />
            删除
          </div>
        </div>
      </el-col>
      <el-col :span="10">
        <div v-if="form.videoSrc===''">
          <el-upload
            ref="videoUpload"
            class="model-upload-box"
            drag
            :headers="getHeads()"
            :action="getUploadVideoUrl()"
            :before-upload="beforeVideoUpload"
            :on-success="onVideoUploadSuccess"
            :on-error="onvideoUploadError"
            :disabled="isCheck"
          >
            <div class="btn-place">
              <svg-icon name="video" height="33" />
              <div class="edit-btn">
                <el-row>
                  <el-col :span="17">
                    <div class="edit-name">视频</div>
                  </el-col>
                  <el-col :span="7">
                    <div class="edit-status unedit">
                      <i class="el-icon-minus" />
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="notice-text color">
              点击或将文件拖拽到这里上传
            </div>
            <div class="file-limit color">
              支持扩展名: .mp4/.avi/.mov
            </div>
          </el-upload>
        </div>
        <div v-else class="pic-box">
          <div class="play-box">
            <video-player
              ref="videoPlayer"
              class="video video-player vjs-custom-skin"
              :playsinline="true"
              :options="playerOptions"
              accept="video/mp4, video/avi, video/mov"
              lazy
            />
          </div>
          <div v-if="!isCheck" class="delete-box" @click="deleteSrc('video')">
            <i style="font-size: 6px" class="el-icon-delete" />
            删除
          </div>

          <!-- <el-input class="frame" placeholder="请输入设计说明，字数限制200字" :rows="5" type="textarea" v-model="specification"  /> -->
        </div>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import ExifReader from 'exifreader'
import { ModelObj, ModelCollada, ModelFbx } from 'vue-3d-model'
import { videoPlayer } from 'vue-video-player'
import { pr } from '@/utils/preview'
import { getAuthorizationHeaderObj } from '../../utils/auth'
import 'video.js/dist/video-js.css'
import '@/icons/picture'
import '@/icons/model'
import '@/icons/video'
import '@/icons/bitian'
import { uploadPicUrl, uploadVideoUrl, uploadModelUrl } from '@/api/work'
export default {
  name: 'EnrollInfo',
  components: { ModelCollada, videoPlayer, ModelObj, ModelFbx },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    preform: {
      type: Object
    },
    isPicUpload: {
      type: Boolean
    },
    isModelUpload: {
      type: Boolean
    },
    isVideoUpload: {
      type: Boolean
    },
    isCheck: {
      type: Boolean
    },
    // 是否正在上传
    isLoading: {
      type: Boolean
    }
  },
  data() {
    return {
      modelSuffix: '',
      isUpload: {
        pic: this.isPicUpload,
        video: this.isVideoUpload,
        model: this.isModelUpload
      },
      loadlist: [0, 0, 0], // 0:版图，1:模型，2:视频
      // 参赛信息
      form: {
        workName: '', // 作品名称
        authorName: '', // 作者姓名
        birthday: '', // 出生日期
        gender: '', // 姓别
        identityNumber: '', // 身份证号
        studentType: '', // 学生类别
        phone: '', // 手机号码
        email: '', // 作者邮箱
        groupMember: '', // 小组成员（非必须）
        racetrack: '', // 参赛通道
        category: '', // 参赛类别
        university: '', // 所属高校
        department: '', // 所在院系
        grade: '', // 所在年级
        studentNumber: '', // 作者学号
        teacherName: '', // 指导老师
        address: '', // 通讯地址
        specification: '', // 说明
        posterSrc: '', // 平面图url
        modelSrc: '', // 模型url
        videoSrc: '111', // 视频url
        id: parseInt(this.$route.params.workId),
        contestName: '',
        acceptance: '',
        contestId: parseInt(this.$route.params.id),
        studentId: null,
        boardId: null
      },

      // 显示视频相关, 每次加载页面的时候设定，这样可以动态改变 sources 中的 src
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          // type: 'video/mp4', // 类型
          // src: '/Users/marianna/desktop/test.mp4' // url地址
          // !!!!! 修改此处
          src: ''
        }],
        poster: '', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
        }
      }
    }
  },
  watch: {
    // 子组件监听父组件的变化并将新值传给父组件
    isCheck(nv) {
      this.isCheck = nv
    },
    preform(nv) {
      this.form = Object.assign({}, nv)
      console.log('pre', this.preform)
      if (this.isUpload.video) {
        console.log('videosrc', this.form.videoSrc)
        this.setVideoPlayerSrc(this.form.videoSrc)
      }
    },
    isPicUpload: {
      handler(nv, ov) {
        console.log('watch', ov)
        this.isPicUpload = nv
        this.isUpload.pic = nv
        console.log('watch pic', this.isUpload.pic)
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.form = Object.assign({}, this.preform)
    this.isUpload.pic = this.isPicUpload
    this.isUpload.video = this.isVideoUpload
    this.isUpload.model = this.isModelUpload
    // this.setVideoPlayerSrc(this.form.videoSrc)
  },
  methods: {
    previewTiny: (url) => {
      return pr.previewTiny(url)
    },
    getHeads() {
      return getAuthorizationHeaderObj()
    },
    getUploadModelUrl() {
      return uploadModelUrl(this.globalGetUsername())
    },
    getUploadPicUrl() {
      return uploadPicUrl(this.globalGetUsername())
    },
    getUploadVideoUrl() {
      return uploadVideoUrl(this.globalGetUsername())
    },
    getModelSuffix() {
      // console.log(this.form.modelSrc.substring(this.form.modelSrc.lastIndexOf('.')))
      return this.form.modelSrc.substring(this.form.modelSrc.lastIndexOf('.') + 1)
    },
    // 发现文件上传有更新时
    beforePicUpload(file, fileList) {
      console.log('file', file)
      console.log('filelist', fileList)
      let isRGB = false
      // this.judgePicColorMode(file)
      let ready = false
      const _this = this
      const uploadComponent = this.$refs.boardUpload

      // 用来控制filereader的异步问题，每1秒执行一遍check()
      // 只有当filereader执行完毕后再进行判断文件大小、上传的操作
      var check = function() {
        if (ready === true) {
          const sizeLt = file.size / 1024 / 1024 <= 5
          const ans = sizeLt && isRGB
          if (!ans) {
            _this.$message.warning('请上传符合要求的文件')
            uploadComponent.clearFiles()
            return ans
          }
          console.log('file state', file.status)
          if (file.status === 'ready') {
            _this.loadlist[0] = 1
            _this.checkLoadingList()
          }
          uploadComponent.submit()
          return ans
        }
        setTimeout(check, 1000)
      }
      check()

      const fileReader = new FileReader()
      fileReader.readAsArrayBuffer(file.raw)
      fileReader.onload = function(event) {
        const img = ExifReader.load(event.target.result, { expanded: true })
        const { file: { 'Color Components': { value }}} = img // value = 3 是rgb模式
        console.log('value', value)
        isRGB = value === 3
        console.log('check rgb on', isRGB)
      }
      fileReader.onloadend = function() {
        console.log('check rgb end', isRGB)
        ready = true
      }
    },
    beforeVideoUpload(file) {
      const sizeLt = file.size / 1024 / 1024 <= 30
      const ans = sizeLt
      if (!ans) {
        this.$message.error('请上传符合要求的文件')
        this.$refs.videoUpload.clearFiles()
        // 没有正在上传的文件
        if (this.loadlist.indexOf(1) === -1) {
          this.checkLoadingList()
        }
        return ans
      }
      this.loadlist[1] = 1
      this.checkLoadingList()
      return ans
    },
    beforeModelUpload(file) {
      let isQualifiedType = false
      const fileType = file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length).toLowerCase()
      isQualifiedType = ['obj', 'dae', 'fbx'].includes(fileType)
      const sizeLt = file.size / 1024 / 1024 <= 30
      const ans = sizeLt && isQualifiedType
      if (!ans) {
        this.$message.error('请上传符合要求的文件')
        this.$refs.modelUpload.clearFiles()
        // 没有正在上传的文件
        this.checkLoadingList()
        return ans
      }
      this.loadlist[2] = 1
      this.checkLoadingList()
      return ans
    },
    // 上传成功
    onPicUploadSuccess(response) {
      if (response.code.toLowerCase() === 'error') {
        this.loadlist[0] = 0
        this.checkLoadingList()
        this.$message.warning('文件上传失败')
        this.$refs.boardUpload.clearFiles()
        return
      }
      this.loadlist[0] = 0
      this.isUpload.pic = true
      this.form.posterSrc = response.data
      this.checkLoadingList()
    },
    onVideoUploadSuccess(response) {
      if (response.code.toLowerCase() === 'error') {
        this.loadlist[1] = 0
        this.checkLoadingList()
        this.$message.warning('文件上传失败')
        this.$refs.videoUpload.clearFiles()
        return
      }
      this.loadlist[1] = 0
      const fileName = response.data
      this.isUpload.video = true
      this.form.videoSrc = fileName
      this.setVideoPlayerSrc(fileName)
      this.checkLoadingList()
    },
    onModelUploadSuccess(response) {
      if (response.code.toLowerCase() === 'error') {
        this.loadlist[2] = 0
        this.checkLoadingList()
        this.$message.warning('文件上传失败')
        this.$refs.modelUpload.clearFiles()
        return
      }
      this.loadlist[2] = 0
      this.isUpload.model = true
      this.form.modelSrc = response.data
      this.checkLoadingList()
    },
    onPicUploadError(err, file, fileList) {
      console.log('err', err)
      this.$message.warning('文件上传失败')
      this.$refs.boardUpload.clearFiles()
      this.loadlist[0] = 0
      this.checkLoadingList()
    },
    onModelUploadError(err, file, fileList) {
      console.log('err', err)
      this.$message.warning('文件上传失败')
      this.$refs.modelUpload.clearFiles()
      this.loadlist[1] = 0
      this.checkLoadingList()
    },
    onVideoUploadError(err, file, fileList) {
      console.log('err', err)
      this.$message.warning('文件上传失败')
      this.$refs.videoUpload.clearFiles()
      this.loadlist[2] = 0
      this.checkLoadingList()
    },
    setVideoPlayerSrc(videoFileUrl) {
      const v = {
        src: pr.previewOrigin(videoFileUrl)
      }
      this.$set(this.playerOptions.sources, 0, v)
    },
    // 上一步
    // goForward() {
    //   this.$emit('form', this.form)
    //   this.$parent.goForward()
    // },
    // 验证上传信息
    validateDisplayInfo() {
      if (this.form.specification !== '' && this.form.posterSrc !== '' && (this.form.modelSrc !== '' || this.form.videoSrc !== '')) {
        this.$emit('form', this.form)
        return true
        // this.$parent.goBackward()
      } else {
        this.$message.error('请确保设计说明和平板版式正确填写和上传，模型和视频文件至少上传一个')
        return false
      }
    },
    // 删除调用
    deletePicSrc() {
      this.form.posterSrc = ''
      this.isUpload.pic = false
    },
    deleteVideoSrc() {
      this.form.videoSrc = ''
      this.isUpload.video = false
    },
    deleteModelSrc() {
      this.form.modelSrc = ''
      this.isUpload.model = false
    },
    // 删除数据
    deleteSrc(name) {
      if (this.isCheck) {
        this.$message.error('请进入修改界面再进行更改')
      } else {
        let n = ''
        n = name
        switch (n.toLowerCase()) {
          case 'model':
            this.deleteModelSrc()
            break
          case 'video':
            this.deleteVideoSrc()
            break
          default:
            this.deletePicSrc()
            break
        }
      }
    },
    // 判断目前是否正在有文件上传
    checkLoadingList() {
      // 没有正在上传的文件
      if (this.loadlist.indexOf(1) === -1) {
        this.isLoading = false
      } else {
        this.isLoading = true
      }
      this.$emit('update:isLoading', this.isLoading)
    }
  }
}
</script>

<style>
.el-textarea.is-disabled .el-textarea__inner {
  color: #606266;
  background-color: #fff;
}
</style>

<style scoped>
@import '~@/styles/theme.css';

.info-container {
  display: inline-block;
  width: 70%;
  /* height: 280px; */
  margin: auto;
  /* border: 1px solid pink; */
}

.info-row {
  height: 130px;
  margin-top: 10px;
  /* border: 1px solid pink; */

}

.title {
  position: relative;
  height: 100px;
  line-height: 100px;
  /* border: 1px solid pink; */
}

.limit {
  position: absolute;
  text-align: center;
  width: 100%;
  height: 20px;
  line-height: 20px;
  /* border: 1px solid red; */
  top: 60px;
  color: #b8b8b8;
  font-size: 10px;
}

.must-fill {
  position: absolute;
  top: 0px;
}

.frame {
  width: 100%;
  /* height: 100px; */
}

.frame /deep/ .el-textarea__inner {
  border: 1px solid #bdbbbb;
}

.frame /deep/ .el-textarea__inner::placeholder {
  text-align: center;
  line-height: 100px;
}

.pic-box {
  position: relative;
  width: 100%;
  height: 130px;
  border: 1px solid #bdbbbb;
  border-radius: 4px;
  /* text-align: left; */
  padding: 5px;
}

/* 平面版图 */
.pic-upload-box /deep/ .el-upload-dragger {
  width: 525px;
  height: 130px;
  border: 1px solid #bdbbbb;
  border-radius: 4px;
}

.img-delete-box {
  position: absolute;
  bottom: 2px;
  right: 158px;
  font-size: 6px;
}

.img-delete-box:hover {
  cursor: pointer;
  color: var(--theme-color);
}

.model-upload-box /deep/ .el-upload-dragger {
  width: 259.58px;
  height: 130px;
  border: 1px solid #bdbbbb;
  border-radius: 4px;
}

/* 视频展示框 */
.play-box {
  display: inline-block;
  /* text-align: left; */
  margin-top: 0.3px;
  height: 117px;
  width: 208px;
}

.delete-box {
  display: inline-block;
  font-size: 6px;
  padding-bottom: 10px;
  /* color:  var(--theme-color); */
  /* border: 1px solid red; */
}

.delete-box:hover {
  cursor: pointer;
  color: var(--theme-color);
}

.btn-place {
  margin-top: 27px;
  /* border: 1px solid pink; */
  text-align: center;
}

.edit-btn {
  display: inline-block;
  width: 140px;
  /* margin-top: 25px; */
  margin-left: 13px;
  height: 33px;
  /* border: 1px solid rgb(194, 189, 189); */
  border-radius: 10px;
  background-color: var(--background-color);
  color: white;
  font-weight: 800;
  font-size: 20px;
  line-height: 33px;
  text-align: left;
}

.edit-name {
    /* display: inline-block; */
    /* padding-left: 10px; */
    text-align: center;
    /* border: 1px solid rgb(248, 248, 248); */
}

.edit-status {
    border-radius: 0 10px 10px 0;
    text-align: center;
    color: white
}

.unedit {
    background-color: var(--theme-color);
}

.edited {
    background-color: green;
}

.notice-text {
  margin-top: 0px;
  font-size: 13px;
}

.file-limit {
  font-size: 6px;
}

.color {
  color: #7c7c7c;
}

/* 视频 */
.video {
  height: 100%;
}

.go-next {
  display: inline-block;
  width: 500px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
  /* border: 1px solid red; */
}

.icon {
  display: inline-block;
  /* height: 40px; */
  /* line-height: 40px; */
  /* padding-top: 5px; */
  /* padding-top:  */
  /* border: 1px solid red; */
}

.icon:hover {
  cursor: pointer;
}

.btn {
  width: 120px;
  margin-left: 30px;
  margin-right: 30px;
}

.el-button {
  border-radius: 15px;
}
</style>

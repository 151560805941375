import dayjs from 'dayjs'

export const time = {
  // 获得时间的年份
  getYear(time) {
    return dayjs(time).format('YYYY')
  },
  // 获得格式化的时间
  getCommonTime(time) {
    return dayjs(time).format('YYYY-MM-DD')
  },
  // 获得小时
  getHours(time) {
    return dayjs(time).format('hh-mm')
  }

}
